
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import MasterLayout from '@/components/MasterLayout.vue';
import PostHeader from '@/components/NonLoggedInComponents/PostHeader.vue';
import PostIntro from '@/components/NonLoggedInComponents/PostIntro.vue';
import PostInfoHeader from '@/components/NonLoggedInComponents/PostInfoHeader.vue';
import PostInfoImg from '@/components/NonLoggedInComponents/PostInfoImg.vue';
import PostContact from '@/components/NonLoggedInComponents/PostContact.vue';
import PostSpacer from '@/components/NonLoggedInComponents/PostSpacer.vue';
import PostCard from '@/components/NonLoggedInComponents/PostCard.vue';
import PostDivider from '@/components/NonLoggedInComponents/PostDivider.vue';
import PostInfoImg2Col from '@/components/NonLoggedInComponents/PostInfoImg2Col.vue';
import J1LGetStarted from '@/components/NonLoggedInComponents/J1LGetStarted.vue';
@Component({
  components: {
    MasterLayout,
    PostHeader,
    PostIntro,
    PostInfoHeader,
    PostInfoImg,
    PostInfoImg2Col,
    PostContact,
    PostCard,
    PostDivider,
    PostSpacer,
    J1LGetStarted
  }
})
export default class FoodLabels extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  metaInfo(): any {
    return {
      meta: [
        {
          name: 'description',
          content:
            'Get high-quality labels for food products—all digital custom labels from Just 1 Label. Register to get free quotes and order custom labels online 24/7.'
        }
      ],
      title: 'Food Product Labels, Digitally Printed Labels for Food Products'
    };
  }
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
